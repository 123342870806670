import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RoleBasedDirective } from './directives/role.based.directive';
import { TranslateApi } from './pipes/api-translate.pipe';
import { AppDateFormattedPipe } from './pipes/date.pipe';
import { DATEPICKER_FORMATS, MomentUtcDateAdapter } from './providers/date.adapter';


@NgModule({
  declarations: [
    TranslateApi,
    RoleBasedDirective,
    AppDateFormattedPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslateApi,
    RoleBasedDirective,
    AppDateFormattedPipe
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ]
})
export class SharedModule { }
