import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

type UTCType = 'UTC' | 'Offset' | 'Extra5Hours';
@Pipe({ name: 'dateFormatted' })
export class AppDateFormattedPipe implements PipeTransform {
  transform(
    date: Date | string | undefined,
    format: string,
    utc: UTCType = 'UTC'
  ): string {
    if (!date) {
      return '';
    }

    if (utc === 'UTC') {
      return moment.utc(date).local().format(format);
    }
    if (utc === 'Offset') {
      return moment(date).format(format);
    }
    return moment(date).add(5, 'h').format(format);
  }
}

@Pipe({ name: 'dateFromNow' })
export class AppDateFromNowPipe implements PipeTransform {
  transform(date: Date | string, utc?: 'UTC'): string {
    const now = moment();
    let momentDate;

    if (utc && utc === 'UTC') {
      momentDate = moment.utc(date);
    } else {
      momentDate = moment(date);
    }

    return momentDate.from(now);
  }
}
