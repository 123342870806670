import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { RolesService } from '../services/roles.service';

@Directive({
  selector: '[appRoleBased]'
})
export class RoleBasedDirective implements OnInit {
  @Input('appRoleBased') allowedRoles!: string[];

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rolesService: RolesService) { }

  ngOnInit() {
    this.rolesService.hasPermission(this.allowedRoles).then((allow) => {
      if (allow) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}